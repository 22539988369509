<template>
  <div class="element materialShadow notification"
       :style="{ backgroundColor: config.publicProperties.ThemeAnnouncementCardBg}">
    <ul>
      <li class="top">
        <span :style="{ color: config.publicProperties.ThemeAnnouncementAuthor }">
          {{ announcement.creatorName }}
        </span>
        {{ announcement.name }}
      </li>
      <li class="content">
        <img v-if="announcement.picName && announcement.picName.length > 0"
             :src="config.imageBaseUrl+announcement.picName"
             @click="showLargeImage(config.imageBaseUrl+announcement.picName)">
        <div v-html="announcement.comment"
             :style="{ color: config.publicProperties.ThemeAnnouncementMainTxt }"></div>
      </li>
    </ul>
    <div class="lsc-clear"></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpPut } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';

  export default {
    name: 'announcement-details',
    props: ['announcement'],
    emits: [ComponentEventNames.didLoad],
    computed: {
      ...mapState(['config']),
    },
    async mounted() {
      this.$emit(ComponentEventNames.didLoad);
      await this.markAnnouncementRead();
    },
    methods: {
      showLargeImage() {
        this.$store.commit('setZoomImage', this.config.imageBaseUrl + this.announcement.picName);
      },
      async markAnnouncementRead() {
        if (this.announcement && this.announcement.id) {
          await httpPut(`users/me/announcements/${this.announcement.id}`, {});
        }
      },
    },
  };
</script>
